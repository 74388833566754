<template>
  <molecule-card-wrapper class="other-rooms">
    <atom-heading type="h2" variant="sm" class="other-rooms__heading">
      Other rooms
    </atom-heading>
    <molecule-community class="other-rooms__community">
      <template v-slot:community>Journalists_Community</template>
      <template v-slot:cta>Join</template>
    </molecule-community>
    <molecule-community class="other-rooms__community">
      <template v-slot:community>Parlementaires_Community</template>
      <template v-slot:cta>Join</template>
    </molecule-community>
    <molecule-community class="other-rooms__community">
      <template v-slot:community>Societe_civile_Community</template>
      <template v-slot:cta>Join</template>
    </molecule-community>
    <atom-button variant="primary md full" class="other-rooms__button">
      Voir tout
    </atom-button>
  </molecule-card-wrapper>
</template>

<script>
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import MoleculeCommunity from "../MoleculeCommunity.vue";
import AtomHeading from "@/components/atoms/AtomHeading.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";
export default {
  components: {
    AtomHeading,
    AtomButton,
    MoleculeCardWrapper,
    MoleculeCommunity
  }
};
</script>

<style lang="scss" scoped>
.other-rooms {
  &__heading {
    @apply mb-[22px];
  }
  &__community {
    @apply mb-[22px];
  }
  &__button {
    @apply font-medium box-border mt-1;
  }
}
</style>
