<template>
  <div class="container mx-auto px-4">
    <molecule-profile-header></molecule-profile-header>
    <molecule-other-rooms-card></molecule-other-rooms-card>
  </div>
</template>

<script>
import MoleculeOtherRoomsCard from "@/components/molecules/cards/MoleculeOtherRoomsCard.vue";
import MoleculeProfileHeader from "@/components/molecules/MoleculeProfileHeader.vue";
export default {
  components: {
    MoleculeOtherRoomsCard,
    MoleculeProfileHeader
  }
};
</script>

<style lang="scss" scoped></style>
