<template>
  <div class="profile-header">
    <div class="profile-header__infos">
      <atom-image
        :src="require('@/assets/images/avatar.jpeg')"
        variant="rounded md"
        class="profile-header__avatar"
      >
      </atom-image>
      <div class="profile-header__user">
        <atom-heading type="h2" variant="sm" class="profile-header__name"
          >Karim Chelloul</atom-heading
        >
        <span class="profile-header__role">Journaliste</span>
        <span class="profile-header__followers">12 Followers, 0 Following</span>
      </div>
    </div>
    <atom-button variant="dark-outline" class="profile-header__button">
      <span class="profile-header__button-text">Modifier</span>
      <atom-icon icon="pencil" class="profile-header__button-icon"></atom-icon>
    </atom-button>
  </div>
</template>

<script>
import AtomImage from "@/components/atoms/AtomImage.vue";
import AtomHeading from "@/components/atoms/AtomHeading.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";
import AtomIcon from "@/components/atoms/AtomIcon.vue";
export default {
  components: {
    AtomImage,
    AtomHeading,
    AtomButton,
    AtomIcon
  }
};
</script>

<style lang="scss" scoped>
.profile-header {
  @apply my-4 flex items-start justify-between;
  &__infos {
    @apply flex gap-3;
  }
  &__avatar {
  }
  &__user {
    @apply flex flex-col justify-center;
  }
  &__name {
    @apply mb-1;
  }
  &__role {
    @apply mb-1 text-black/80 text-sm;
  }
  &__followers {
    @apply text-sm;
  }
  &__button {
    @apply flex items-center gap-2 border-2;
    &-icon {
      @apply fill-[#012230];
    }
    &-text {
    }
  }
}
</style>
